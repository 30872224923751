import React, { HTMLProps } from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../../../utils/common';
import { ProSectionContainer } from '../ProSectionContainer';

type NodeTypes = {
   image?: HTMLProps<HTMLImageElement>
   title?: string
   subTitle?: string
   eyebrow?: string
   description?: string
};

type CardOrientationContainerProps = {
   title?: string
   subTitle?: string
   eyebrow?: string
   description?: string
   nodes?: NodeTypes[]
   rootClassName?: string
}

const rootCls = cva(
   'flex flex-col gap-10 items-center justify-start w-full overflow-x-hidden'
)

const innerCls = cva(
   'flex flex-col gap-5 items-center justify-center layout overflow-hidden'
)

const orientationContainerCls = cva(
   'flex items-center w-full gap-0 md:gap-10 justify-between layout',
   {
      variants: {
         isOdd: {
            true: 'flex-col md:flex-row',
            false: 'flex-col md:flex-row-reverse'
         }
      }
   }
)

const wrapperRight = cva(
   'w-full  md:w-[50%] flex flex-row px-2 mg:px-0',
   {
      variants: {
         isOdd: {
            true: '[&>img]:mr-auto justify-end',
            false: '[&>img]:ml-auto justify-start'
         }
      }
   }
)

export const CardOrientationContainer = React.forwardRef
   <HTMLDivElement, HTMLProps<HTMLDivElement> & CardOrientationContainerProps>((props, ref) => {

      const {
         nodes = [],
         className,
         rootClassName,
         ...rest
      } = props;

      return (
         <div
            className={rootCls(
               { className: rootClassName }
            )}
            {...rest}
            ref={ref}
         >
            <div
               className={innerCls(
                  { className }
               )}
            >
               <TextViewer
                  titleProps={{ as: 'h2' }}
                  align='center'
                  {...rest}
                  children={
                     <div className='mt-10  overflow-x-hidden'>
                        {nodes.map((node, index) => {
                           index++;

                           const {
                              image,
                              ...nodeRest
                           } = node;

                           const isOdd = index % 2 !== 0;

                           return (
                              <div
                                 key={index}
                                 className={
                                    orientationContainerCls({ isOdd })
                                 }
                                 data-aos={isOdd ? 'fade-down-right' : 'fade-down-left'}
                              >
                                 <div className='w-full md:w-[50%]'>
                                    <TextViewer titleProps={{ as: 'h4', strong: 'regular' }} {...nodeRest} />
                                 </div>
                                 <div className={wrapperRight({ isOdd })}>
                                    {image && image.src && (
                                       <img alt={node?.title || node?.subTitle} className='rounded-2xl flex-1 w-[300px] md:w-[330px] xl:w-[430px] h-[300px] md:h-[330px] xl:h-[360px] 2xl:h-[400px]' {...image} />
                                    )}
                                 </div>
                              </div>
                           )
                        })}
                     </div>
                  }
               />
            </div>
         </div>
      )
   })

type TextViewerProps = {
   align?: 'start' | 'center' | 'end'
   titleProps?: any
} & Omit<NodeTypes, 'image'>

const textViewerRootCls = cva(
   'flex flex-col items-center justify-center gap-5'
)


const TextViewer = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & TextViewerProps>(
   (props, ref) => {

      const {
         eyebrow,
         title,
         description,
         subTitle,
         className,
         align = 'start',
         titleProps,
         children = null,
         ...rest
      } = props;

      return (
         <div
            ref={ref}
            className={textViewerRootCls({ className })}
            {...rest}
         >
            <ProSectionContainer
               title={title}
               titleProps={{
                  as: 'h2',
                  strong: 'bold',
                  ...titleProps
               }}
               align={align}
               description={description ?? subTitle}
               eyebrow={eyebrow}
               nodeType='html'
               extraNodes={children as any}
            />
         </div>
      )
   })