// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-careers-details-index-tsx": () => import("./../../../src/pages/careers-details/index.tsx" /* webpackChunkName: "component---src-pages-careers-details-index-tsx" */),
  "component---src-pages-classic-index-js": () => import("./../../../src/pages/classic/index.js" /* webpackChunkName: "component---src-pages-classic-index-js" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-integrations-index-tsx": () => import("./../../../src/pages/integrations/index.tsx" /* webpackChunkName: "component---src-pages-integrations-index-tsx" */),
  "component---src-pages-integrations-sticky-container-tsx": () => import("./../../../src/pages/integrations/sticky-container.tsx" /* webpackChunkName: "component---src-pages-integrations-sticky-container-tsx" */),
  "component---src-pages-lets-chat-header-tsx": () => import("./../../../src/pages/letsChat/Header.tsx" /* webpackChunkName: "component---src-pages-lets-chat-header-tsx" */),
  "component---src-pages-lets-chat-index-tsx": () => import("./../../../src/pages/letsChat/index.tsx" /* webpackChunkName: "component---src-pages-lets-chat-index-tsx" */),
  "component---src-pages-lets-chat-section-side-1-tsx": () => import("./../../../src/pages/letsChat/SectionSide1.tsx" /* webpackChunkName: "component---src-pages-lets-chat-section-side-1-tsx" */),
  "component---src-pages-lets-chat-section-side-2-tsx": () => import("./../../../src/pages/letsChat/SectionSide2.tsx" /* webpackChunkName: "component---src-pages-lets-chat-section-side-2-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-careers-detail-js": () => import("./../../../src/templates/careers-detail.js" /* webpackChunkName: "component---src-templates-careers-detail-js" */),
  "component---src-templates-letschat-js": () => import("./../../../src/templates/letschat.js" /* webpackChunkName: "component---src-templates-letschat-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

