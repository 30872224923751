import React, { useState } from 'react';

type Option = { name: string; value: string };

type FilterProps = {
   isMulti?: boolean
   selected?: Array<string> | string
   options: Option[]
   onSelect: (selected: string | string[]) => void
}

export const Filter = (props: Partial<FilterProps>) => {

   const {
      isMulti = false,
      options = [],
      selected: selectedProp,
      onSelect: onSelectProp
   } = props;

   const [selectedLocal, setSelectedLocal] = useState<Array<string> | (string | null)>(() => {
      return isMulti ? [] : null
   });

   const selected = selectedProp ?? selectedLocal;

   const onSelect = (newSelected: string) => {
      if (isMulti && Array.isArray(selected)) {
         let updated = selected;
         if (selected.includes(newSelected)) {
            updated = updated.filter((i) => i !== newSelected)
         } else {
            updated = [...selected, newSelected];
         }
         setSelectedLocal(updated);
         typeof onSelectProp === "function" && (
            onSelectProp(updated)
         )
      } else {
         // single selection
         setSelectedLocal(newSelected);
         typeof onSelectProp === "function" && (
            onSelectProp(newSelected)
         )
      }
   }

   return (
      <div className='flex flex-row m-auto gap-3'>
         {options.map((option, index) => {
            const { name, value } = option;

            let active = value === selected;
            if (isMulti && Array.isArray(selected)) {
               active = selected.includes(value);
            }
            return (
               <button
                  key={index}
                  className={"careers-badge " + `${active ? 'careers-badge-selected' : ''}`}
                  onClick={() => onSelect(value)}
               >
                  <span className="w-2 h-2 bg-orange-500 rounded-full mr-2"></span>
                  <span className="careers-badge-title">
                     {name}
                  </span>
               </button>
            )
         })}
      </div>
   )
};