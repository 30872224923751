import React, { CSSProperties } from 'react';
import { Link } from 'gatsby';

import type { JobType } from './job';
import { DETAILS_PAGE_BASE, MAIL_TO } from './constant';

declare global {
   interface Window { tf: any }
}

const ROOT_STYLE: CSSProperties = {
   backgroundColor: '#f3f6fc'
}

const PARAGRAPH_STYLES: CSSProperties = {
   lineHeight: '1.8rem'
}

type JobCard = {
   post: Partial<JobType>
}

export const JobCard = (props: JobCard) => {

   const { post } = props;

   const {
      title,
      summary,
      tags,
      id
   } = post;


   const onApply = () => {
      const subject = `Applying for position - ${title}`;
      (window as any).location = `mailto:${MAIL_TO}?subject=${subject}`
   }

   return (
      <>
         <CardRoot>
            <CardContainer>
               <div className="w-full">
                  <div className='flex justify-between w-full gap-10'>
                     <CardTitle>
                        {title}
                     </CardTitle>
                     <div className='flex flex-col gap-1'>
                        {tags?.map((tag, index) => {
                           return (
                              <div key={index} className='flex items-center gap-2'>
                                 <span className="w-2 h-2 bg-orange-500 rounded-full mr-2"></span>
                                 <span className="careers-badge-title mt-1 text-xl font-semibold">
                                    {tag}
                                 </span>
                              </div>
                           )
                        })}
                     </div>
                  </div>
               </div>

               <CardParagraph>
                  {summary}
               </CardParagraph>

               <div className='flex justify-between items-end w-full gap-10'>
                  <div className="flex justify-between items-center w-full">
                     <Link
                        to={`${DETAILS_PAGE_BASE}${id}`}
                        className="font-medium hover:underline flex items-center text-lg"
                     >
                        Learn more
                        <span className="ml-2 text-lg text-orange-500">→</span>
                     </Link>
                     <button
                        id={id}
                        className="btn btn-primary btn-lg"
                        onClick={onApply}
                     >
                        Apply Now →
                     </button>
                  </div>
               </div>
            </CardContainer>
         </CardRoot>
      </>
   )
};

const mergeCls = (arg1: string, arg2: string) => (
   [arg1, arg2].join(' ')
)

type CardRootProps = {} & React.HTMLProps<HTMLDivElement>

export const CardRoot = (props: CardRootProps) => {

   return (
      <div
         {...props}
         className={(
            mergeCls("p-10 rounded-lg max-w-lg mx-auto",
               props?.className as string)
         )}
         style={{ ...ROOT_STYLE, ...props?.style }}
      />
   )
}

type CardContainerProps = {} & React.HTMLProps<HTMLDivElement>
export const CardContainer = (props: CardContainerProps) => {
   return (
      <div
         {...props}
         className={(
            mergeCls("flex flex-col",
               props?.className as string)
         )}
         style={{ ...{ gap: 26 }, ...props?.style }}
      />
   )
}

type CardTitleProps = {} & React.HTMLProps<HTMLHeadingElement>

export const CardTitle = (props: CardTitleProps) => {
   return (
      <h2
         className={(
            mergeCls("text-xl font-bold text-gray-900 leading-9", props?.className as string)
         )}
         {...props}
      />
   )
}

type CardParagraphProps = {} & React.HTMLProps<HTMLParagraphElement>

export const CardParagraph = (props: CardParagraphProps) => {
   return (
      <p
         {...props}
         className={(
            mergeCls("text-gray-800 text-md", props?.className as string)
         )}
         style={{ ...PARAGRAPH_STYLES, ...props?.style }}
      />
   )
}