import { SectionContainer, ProSectionContainer, MediaInfoCard, CommunitySection, ProCard, FancyContainer, JobOpenings } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProSectionContainer,
  MediaInfoCard,
  CommunitySection,
  ProCard,
  FancyContainer,
  JobOpenings,
  React
};