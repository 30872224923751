import React, { useState } from 'react';
import { Filter } from './filter';
import allPosts from './posts.json';
import { CardContainer, CardParagraph, CardRoot, CardTitle, JobCard } from './job-card';
import { JobType } from './job';

enum JobTag {
   ENGINEERING = 'Engineering',
   MARKETING = 'Marketing',
   ALL = 'all'
}

const OPTIONS = [
   {
      name: 'All Teams',
      value: JobTag.ALL
   },
   {
      name: 'Engineering',
      value: JobTag.ENGINEERING
   },
   {
      name: 'Product',
      value: 'product'
   },
   {
      name: 'Support',
      value: 'support'
   },
   {
      name: 'Marketing',
      value: JobTag.MARKETING
   },
   {
      name: 'Design',
      value: 'design'
   },
   {
      name: 'Sales',
      value: 'sales'
   },
]

export default () => {

   const [selected, setSelected] = useState<string>(JobTag.ALL);

   const [posts, setPosts] = useState<JobType[]>(allPosts)

   const onSelect = (newSelected: string) => {
      if (JobTag.ALL === newSelected) {
         setPosts(allPosts)
      } else {
         setPosts(
            allPosts.filter(({ tags }) => (
               tags.includes(newSelected)
            ))
         )
      }
      setSelected(newSelected);
   }

   return (
      <div className='layout flex flex-col py-10 gap-10'>
         <Filter
            selected={selected}
            options={OPTIONS}
            onSelect={onSelect as any}
         />

         <div className='flex flex-col gap-10 careers-job-container'>
            {posts?.length ? (
               posts.map((post, index) => {
                  return (
                     <JobCard key={index} post={post} />
                  )
               })
            ) : (
               <CardRoot style={{ minWidth: '72rem' }}>
                  <CardContainer
                  >
                     <div className='flex justify-between w-full gap-10'>
                        <CardTitle>
                           Sorry, currently no open roles here
                        </CardTitle>
                        <div className='flex items-center gap-2'>
                           <img src='/icon-set/tag-icon.svg' alt='careers-no-opening-roles' />
                           <span className="careers-badge-title mt-1 text-xl font-semibold">
                              {'Maybe Later'}
                           </span>
                        </div>
                     </div>

                     <CardParagraph>
                        This time, nothing here. Follow our socials to learn about new openings!
                     </CardParagraph>
                  </CardContainer>
               </CardRoot>
            )}
         </div>
      </div>
   )
}