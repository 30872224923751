import React, { HTMLProps, cloneElement, useMemo } from 'react';

import { cva } from 'class-variance-authority';
import { Link } from 'gatsby';
import { Check } from '@styled-icons/bootstrap';
import { cn } from '../../utils/common';

const rootCls = cva(
   'card',
   {
      variants: {
         transparent: {
            true: '!bg-[transparent] !shadow-none !border-none',
            false: ''
         },
      }
   }
)

const innerCls = cva(
   cn('flex flex-col gap-5 items-start justify-start flex-1'),
   {
      variants: {
         align: {
            start: 'items-start ',
            center: 'items-center',
            end: 'items-end'
         },
         gap: {
            lg: 'gap-5',
            sm: 'gap-[.5rem]'
         }
      }
   }
)

const badgeCls = cva(
   'px-[6px] mb-2 text-[.75rem] font-semibold bg-primary-100/10 text-primary-200 rounded-sm'
)

const textCls = cva(
   "leading-7 text-[1rem] list-none   flex-[1_0]",
   {
      variants: {
         align: {
            start: 'text-start ',
            center: 'justify-with-center text-center text-webkit-center',
            end: 'text-end'
         }
      }
   }
)

const imgClassName = cva(
   cn('w-16 h-16 flex-shrink-0'),
   {
      variants: {
         middleImage: {
            true: 'ml-auto mr-auto',
            false: ''
         }
      }
   }
)

export type ProCardProps = {
   title: string
   badge?: string
   description?: any
   link?: {
      name: string
      to: string
   }

   points?: { label: string, type: 'link' | 'text', description?: string }[]
   pointsProps?: {
      gap?: 'lg' | 'sm'
   }

   icon?: React.ReactElement
   image?: HTMLProps<HTMLImageElement>
   transparent?: boolean

   titleCls?: string
   titleSize?: 'sm' | 'md' | 'lg'
   align?: 'start' | 'center' | 'end'
   noAnim?: boolean
   tickSize?: 'small' | 'large'

   middleImage?: boolean
}

const titleCls = cva(
   cn("font-semibold  leading-7"),
   {
      variants: {
         align: {
            start: 'text-start',
            center: 'text-center',
            end: 'text-end'
         },
         size: {
            sm: '',
            md: 'text-[1rem]',
            lg: 'text-[1.7rem] leading-5',
         }
      }
   }
)

const pointsCls = cva(
   cn('flex flex-col mt-2'),
   {
      variants: {
         gap: {
            lg: 'gap-5',
            sm: 'gap-[.5rem]'
         }
      }
   }
)

export const ProCard = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & ProCardProps>((props, ref) => {

   const {
      className,
      title,
      points = [],
      description,
      badge = null,
      pointsProps,
      titleCls: titleClassName = '',
      transparent = false,
      titleSize = 'md',
      middleImage = false,
      link,
      align = 'start',
      icon = null,
      noAnim = false,
      tickSize = 'small',
      image,
      ...rest
   } = props;

   const pointsGap = useMemo(() => pointsProps?.gap ?? 'sm', [pointsProps])

   return (
      <div
         style={{ alignSelf: 'normal' }}
         className={rootCls({ className, transparent, })}
         data-aos={noAnim ? null : "zoom-in"}
         {...rest}
         ref={ref}
      >
         <div className={innerCls({ align, gap: pointsGap })}>

            {icon && (
               <div className='featuredIcon mb-2'>
                  {cloneElement(icon, {
                     className: "h-6 w-6",
                  })}
               </div>
            )}

            {image?.src && (
               <img className={imgClassName({ className: image?.className, middleImage })} src={image.src} alt={image?.alt || title} />
            )}

            {badge && (
               <span className={badgeCls()}>{badge}</span>
            )}

            <span className={titleCls({ className: titleClassName, size: titleSize, align })} >{title}</span>

            {description && (
               <p className={textCls({ align })} >{description}</p>
            )}

            {points.length > 0 && (

               <ul style={{ paddingInlineStart: 0 }} className={pointsCls({
                  gap: pointsGap
               })} >

                  {points.map((point, key) => {

                     const { label, description = null } = point;

                     return (
                        <li className={textCls({ className: `${key !== 0 ? 'mt-2' : ''}` })} key={key}>
                           <div className='flex flex-row gap-3 items-start justify-start'>
                              <Tick size={tickSize} />
                              <div className='flex flex-col gap-1'>
                                 <span className='text-justify' >{label}</span>
                                 {description && (
                                    <span className='text-sm font-[300] leading-7'>{description}</span>
                                 )}
                              </div>
                           </div>
                        </li>
                     )
                  })}

               </ul>
            )}
         </div>
         {link && (
            <Link to={link?.to} className=" text-start btn text-sm btn-link mr-auto">{link?.name}</Link>
         )}
      </div>
   )
})

const tickCls = cva(
   cn('rounded-full w-[19px] h-[19px] mt-2'),
   {
      variants: {
         color: {
            primary: 'bg-primary-200',
            gray: 'bg-gray-800'
         }
      },
      defaultVariants: {
         color: 'gray'
      }
   }
)

const tickIconCls = cva(
   cn('text-white bg-black rounded-full -ml-[1px] flex-shrink-0'),
   {
      variants: {
         size: {
            small: 'w-4 h-4 ',
            large: 'w-7 h-7'
         }
      },
      defaultVariants: {
         size: 'small'
      }
   }
)


type TickProps = {
   color?: 'gray' | 'primary'
   size?: 'small' | 'large'
}

export function Tick({ color = 'gray', size = 'small' }: TickProps) {
   return (
      <div className='flex-shrink-0'>
         <Check className={tickIconCls({ size })} />
      </div>
   )
}