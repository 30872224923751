import React, { HTMLProps } from 'react';
import { cva } from 'class-variance-authority';

import { ProSectionContainer } from '../ProSectionContainer';
import { DataCard } from '../DataCard';

import { cn } from '../../../utils/common';
import { ProCard } from '../ProCard';


type ItemTypes = {
   title: string
   description: string
   points?: { label: string, type: 'link' | 'text' }[]
   image?: HTMLProps<HTMLImageElement>
}

type BgTypes = 'gray' | 'white';

type SegmentedContainerProps = {
   title?: any
   description?: any
   bg?: BgTypes
   items?: ItemTypes[]
   dark?: boolean
};

const rootCls = cva(cn(''), {
   variants: {
      dark: {
         true: '',
         false: ''
      },
      bg: {
         'gray': '',
         'white': '',
      },
   },
   compoundVariants: [
      {
         dark: false,
         bg: 'gray',
         className: 'bg-[var(--ifm-color-secondary-lighter)]'
      },
      {
         dark: false,
         bg: 'white',
         className: 'bg-white'
      },
      {
         dark: true,
         bg: ['gray', 'white'],
         className: 'primary-gradient text-white'
      }
   ]
})

const defaultItems = []

export const SegmentedContainer = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> &
   SegmentedContainerProps
>((props, ref) => {

   const {
      title = '',
      description = '',
      bg = 'gray',
      dark = false,
      items = defaultItems,
      className,
      ...rest
   } = props;

   return (
      <div
         {...rest}
         className={rootCls({ className, dark, bg })}
         ref={ref}
      >
         <ProSectionContainer
            title={title}
            description={description}
            bg={bg}
            dark={dark}
            extraNodes={(
               <div className='layout flex flex-row'>
                  <div className='flex flex-col relative lg:flex-row items-start justify-between gap-5 lg:gap-20'>
                     <div className='hidden md:block '>
                        <div className="vertical-timeline"></div>
                        <div className="timeline-extra-outer-circle d-center">
                           <div className="timeline-outer-circle d-center pulse">
                              <div className="timeline-circle"></div>
                           </div>
                        </div>
                        <div className="vertical-timeline-line start undefined"></div>
                     </div>
                     {items?.map((item, index) => (
                        <Container
                           isFirst={index === 0}
                           isLast={items.length - 1 === index}
                           {...item as any}
                           key={index}
                        />
                     ))}
                  </div>
               </div>
            )}
         />
      </div>
   )

})

type ContainerProps = {
   isFirst?: boolean
   isLast?: boolean
} & ItemTypes

const containerCls = cva(
   cn(' flex flex-col lg:flex-row items-center lg:gap-[1rem] justify-center'),
   {
      variants: {

      }
   }
)

export const Container = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> &
   ContainerProps
>((props, ref) => {

   const {
      title,
      description,
      image,
      points,
      className,
      isFirst,
      isLast,
      ...rest
   } = props;


   return (
      <div className='flex flex-row gap-[3rem] lg:mt-12 relative'>

         <div
            {...rest}
            ref={ref}
            className={containerCls({ className })}
         >
            <ProCard
               transparent
               titleSize='lg'
               className='px-0'
               title={title}
               description={description}
               points={points}
            />

         </div>
      </div>

   )
})